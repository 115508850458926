:root {
  --primaryColor: white;
  --secondaryColor: linear-gradient(150deg, white 40%, blue);
}

.mainContainer {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
}

/* .main {
  text-align: center;
  padding: 0;
  flex-grow: 1;
  background-color: ;
} */

.headerTitle {
  background-color: var(--primaryColor);
  text-align: center;
  padding: 1rem;
  border-bottom: 1px solid black;
}

h2.titleText {
  font-style: normal;
  color: darkcyan;
  font-size: 28px;
  line-height: 36px;
  padding: 0 20px;
  margin-top: 36px;
}

h2.titleText span {
  display: block;
  font-family: sans-serif !important;
}

/**
** SMALL **
**/
@media screen and (min-width: 600px) {
  h2.titleText {
    font-size: 38px;
    line-height: 50px;
    padding: 0 10px;
  }
}

/**
** MEDIUM **
**/
@media screen and (min-width: 900px) {
  h2.titleText {
    font-size: 38px;
    line-height: 50px;
    /* padding: 0 10px; */
    margin-top: 1px;
  }
}

/**
** LARGE **
**/
@media screen and (min-width: 1200px) {
  h2.titleText {
    padding: 0 100px;
  }
}

/**
** EXTRA LARGE **
**/
@media screen and (min-width: 1536px) {
  h2.titleText {
    padding: 0 200px;
  }
}

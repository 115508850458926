.companyCardContainer {
  min-width: 275px !important;
  min-height: 380px !important;
  background-color: "white" !important;
  border: 1 !important;
  margin-bottom: 1 !important;
}

.companyIconContainer {
  min-width: 20px; /* Minimum width before wrapping */
  padding: 10px;
  background-color: white;
  box-sizing: border-box;
}

.companyNameContainer {
  flex: 1;
  min-width: 200px; /* Minimum width before wrapping */
  padding: 10px;
  background-color: white;
  box-sizing: border-box;
}

.companyName {
  margin: 0 0 0px 0;
  font-size: 1.2em;
}

.companyRating {
  font-size: 1.1em;
  color: #ffd700; /* Gold color for stars */
}

.companyButtonContainer {
  flex: 1;
  min-width: 200px; /* Minimum width before wrapping */
  padding: 10px;
  background-color: white; /* #f0f0f0; */
  box-sizing: border-box;
}

.companyButton {
  min-width: 50px; /* Minimum width before wrapping */
  /* padding: 10px; */
}

.companyDetailsContainer {
  flex: 1;
  min-width: 200px; /* Minimum width before wrapping */
  padding-top: 1px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 1px;
  text-align: justify;
  background-color: white;
  box-sizing: border-box;
}

.locationContainer {
  display: flex;
  justify-content: left;
  align-items: center;
  height: 15px;
  margin-top: 5px;
  margin-left: 25px;
}

.companyCategoryContainer {
  flex: 1;
  min-width: 300px; /* Minimum width before wrapping */
  padding-top: 10px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 10px;
  text-align: justify;
  background-color: white;
  box-sizing: border-box;
}

.companyServicesContainer {
  flex: 1;
  min-width: 200px; /* Minimum width before wrapping */
  padding-top: 1px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 1px;
  text-align: justify;
  background-color: white;
  box-sizing: border-box;
}

@media screen and (max-width: 768px) {
  .companyCardContainer {
    min-width: 275px;
    min-height: 380px;
  }

  .companyIconContainer {
    flex: 1;
    min-width: 50px;
    width: 60px;
  }

  .companyNameContainer {
    flex: 1;
    min-width: 150px;
  }

  .companyButtonContainer {
    flex: 1;
    min-width: 150px;
  }

  /* .companyIcon {
    margin-right: 0;
    margin-bottom: 15px;
  } */
}

/* .topInfoTitle {
  padding: 2;
  display: flex;
  flex-direction: column;
  height: 100px;
  margin-top: 9;
  background-color: green;
} */

/* .listContainer {
  background-color: var(--primaryColor);
  text-align: center;
  padding: 1rem;
  border-bottom: 1px solid black;
} */

/* .contentCard {
  min-width: 275;
  min-height: 50;
  background-color: "white";
  border: 1;
} */

/* .cardIcon {
  padding: 12;
  margin: 5;
} */
